#modal {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

#modal-background {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
	word-wrap: break-word;
	position: absolute;
	background-color: white;
	border-radius: 10px;
	padding: 0;
	max-width: auto;
}
