.album-container {
	margin: auto;
	max-width: 2480px;
}

.create-album-form {
	max-width: auto;
	min-height: auto;
	padding: 7px 7px;
	/* margin: auto; */
	border-radius: 10px;
	border: solid 1px #ccc;
	box-shadow: 1px 2px 5px rgb(0 0 0 / 31%);
	background: #ebebeb;
}

.create-album-input {
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	margin-left: 5px;
	max-width: 195px;
	display: flex;
	flex-direction: column;
}

.album-detail {
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	min-width: 720px;
	display: flex;
	justify-content: left;
	max-width: 2480px;
	color: black;
}

.album-list-item {
	display: inline-flex;
	border: orange solid;
	background: linear-gradient(
		135deg,
		rgb(232, 232, 232) 0%,
		rgb(121, 121, 121) 100%
	);

}

.album-list-item:hover {
	cursor: pointer;
}

.ul-albums {
	/* display: inline-flex; */
	border: blue solid;
	/* min-width: 720px; */
	max-width: 2480px;
	padding: 20px;
	gap: 20px;
}

.album-list-title {
	padding-left: 20px;
	font-size: 30px;
	font-weight: bold;
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
}

.album-list-image {
	height: 150px;
	width: 150px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 30%;
}

.album-list-item {
	display: inline-flex;
	justify-content: left;
	margin-top: 20px;
	width: 500px;
	font-size: 30px;

}
