.footer-container {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: rgba(21, 21, 23, 0.95);
	padding: 10px;
	display: flex;
	/* flex-direction: column; */
	justify-content: space-around;
	align-items: center;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.footer-icons {
	display: flex;
	gap: 10px;
	margin-left: 10px;
}

.footer-icon {
	font-size: 24px;
	color: hsl(0, 0%, 90%);
	transition: color 0.3s ease;
}

.footer-icon:hover {
	color: #0077b5;
}

.footer-text {
	font-size: 14px;
	color: hsl(0, 0%, 90%);
	margin-top: 8px;
}
