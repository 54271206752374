.trash-icon,
.edit-icon {
	opacity: 0.5;
	width: 20px;
	height: 20px;
}

.trash-icon:hover,
.edit-icon:hover {
	opacity: 0.8;
	cursor: pointer;
}

.audio-control {
	margin-left: 20px;
	margin-top: 20px;
}

.song-detail-main-container {
	/* border: red solid; */
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
	width: 1175px;
	height: 1100px;
	background-color: rgb(245, 245, 245);
}

.detail-container {
	/* border: blue solid; */
	display: flex;
	width: 50rem;
	height: 20rem;
	margin-top: 2rem;
}

.song-detail-container {
	/* border: blue solid; */
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-rows: 1fr 1fr 1fr;
	justify-items: center;
	width: 1080px;
	height: 1020px;
}
.detail-button-container {
	/* border: purple solid; */
	display: flex;
	width: 160px;
	height: 25px;
	justify-content: right;
	margin-left: 54%;
	/* padding: 10px; */
}

.comments-container {
	/* border: green solid; */
	/* margin-right: 30%; */
	width: 47rem;
}

.song-detail-box {
	/* border: orange solid; */
	display: flex;
	/* justify-content: center; */
	margin: 20px;
	min-height: 10rem;
	min-width: 9rem;
	/* width: 100%; */
	/* height: 275px; */
}

.song-detail-text-box {
	/* border: green solid; */
	/* min-width: fit-content; */
	padding: 3px;
	/* min-height: 9rem;
	min-width: 9rem; */
}

/* .song-detail {
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	border: black solid;
	width: 270px;
	height: 270px;
} */

.song-detail-text-container {
	/* border: red solid; */
	display: flex;
	margin: auto;
	flex-direction: column;
	justify-content: space-between;
	min-width: 30rem;
	height: 17rem;
}
.song-detail-image {
	/* border: green solid; */
	width: 17rem;
	height: 17rem;
}

.song-image:hover {
	border: #f50 solid 1px;
	cursor: pointer;
}
.song-detail-title {
	/* border: black solid; */
	max-width: 250px;
	height: 20px;
}
.song-detail-description {
	/* border: black solid; */
	width: 175px;
	height: 20px;
}

/* .song-detail {
	display: flex;
	justify-content: center;
	width: 80%;
	margin: 0;
}
.song-list-image {
	height: 150px;
	width: 150px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 30%;
}

.song-detail-info {
	display: inline-flex;
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	border: orange solid;
	width: 100%;
	color: black;
	margin-top: 20px;
	margin-right: 5px;
	max-width: 550px;
	opacity: 1;
	height: 100%;
	width: 100%;
	transition: opacity 0.3s;
	background: linear-gradient(
		135deg,
		rgb(232, 232, 232) 0%,
		rgb(121, 121, 121) 100%
	);
} */

li {
	list-style: none;
}

.comments {
	border: lightgrey solid 1px;
}
.comment-list {
	/* border: blue solid; */
	display: flex;
	list-style: none;
	justify-content: space-between;
	padding: 2px;
	/* padding-left: 5px; */
	/* padding-bottom: 5px; */
	/* border: lightgrey solid 0.5px; */
}
.comment-input {
	margin-top: 5px;
	/* margin-left: 5px; */
	width: 35rem;
	height: 3rem;
	resize: none;
}

.ul-comments {
	/* border: blue solid; */
	margin-top: 10px;
}

#song-title {
	font-size: 30px;
	font-weight: bold;
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
}

#song-artist {
	font-size: 20px;
}

.song-detail-buttons {
	margin-top: 20px;
}

.create-song-form {
	max-width: auto;
	min-height: auto;
	padding: 7px 7px;

	border-radius: 10px;
	border: solid 1px #ccc;
	box-shadow: 1px 2px 5px rgb(0 0 0 / 31%);
	background: #ebebeb;
}

.create-song-input {
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	margin-left: 5px;
	max-width: 195px;
	display: flex;
	flex-direction: column;
}

.rhap_play-pause-button {
	background-color: black;
	border-radius: 100%;
	color: rgb(242, 242, 242);
	display: flex;
	justify-content: center;
	align-items: center;
}
