.edit-song-form {
	max-width: auto;
	min-height: auto;
	padding: 7px 7px;
	border-radius: 10px;
	border: solid 1px #ccc;
	box-shadow: 1px 2px 5px rgb(0 0 0 / 31%);
	background: #ebebeb;
}

.edit-input-form {
	max-width: 195px;
	display: flex;
	flex-direction: column;
	/* font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif; */
}

.edit-label-form {
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
}

