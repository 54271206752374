.login-ul {
	padding: 0px;
	margin: 0px;
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	color: red;
	justify-content: left;
}
form {
	border-radius: 100px;
}

.login-button-font-modal {
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	font-size: 16px;
	background-color: black;
	border-color: white;
	color: #fff;
	border-radius: 3px;
	padding: 2px 15px;
	margin-top: 5px;
}
