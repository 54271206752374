#navigation-bar {
	background-color: #333;
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	min-width: 720px;
	/* max-width: 2480px; */
	height: 60px;
	margin: auto;
	position: relative;
}

.button-container {
	display: flex;
	margin-top: 10px;
	justify-content: space-evenly;
	width: 100%;
}

.blue-button {
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	font-size: 14px;
	background-color: blue;
	border: 1px solid blue;
	color: #fff;
	border-radius: 3px;
	font-weight: 100;
	text-align: center;
	vertical-align: initial;
	height: 26px;
	padding: 2px 11px 2px 10px;
}

.hidden_search {
	pointer-events: none;
	opacity: 0;
}

.orange-button {
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	background-color: black;
	font-size: 14px;
	background-color: #f50;
	/* border-color: #f50; */
	color: #fff;
	border-radius: 3px;
	border: 1px solid #f50;
	font-weight: 100;
	text-align: center;
	vertical-align: initial;
	height: 26px;
	padding: 2px 11px 2px 10px;
	box-shadow: none;
}

.grey-button {
	background-color: #333;
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	font-size: 14px;
	color: #fff;
	border-radius: 3px;
	font-weight: 100;
	text-align: center;
	vertical-align: initial;
	border: 1px solid #666;
	height: 26px;
	padding: 2px 11px 2px 10px;
}

button:hover {
	border-color: white;
	cursor: pointer;
}

.logo {
	position: relative;
	max-width: 60px;
	max-height: 30px;
	opacity: 0.7;
}

.logo2 {
	position: relative;
	margin-left: 5px;
	opacity: 0.7;
}

.Nav-container {
	/* display: block; */
	position: relative;
	margin: 0;
	min-width: 720px;
	max-width: 100%;
}

img {
	margin: auto;
	max-width: 100%;
	/* max-height: 100%; */
}

.profile-button {
	position: absolute;
	top: 10px;
	right: 213px;
	width: 40px;
	height: 40px;
	border-radius: 20%;
}

.nav-text-title {
	color: rgb(240, 240, 240);
	font-size: 20px;
	font-weight: bolder;
}

.nav-text {
	color: rgb(230, 230, 230);
	font-size: large;
	font-weight: bold;
	text-decoration: none;
}

.nav-text:hover {
	color: #f50;
	font-size: large;
	font-weight: bold;
	text-decoration: none;
	/* padding: 5px; */
}

.nav-item {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0px 10px 0px 10px;
}

#all-nav-items {
	display: inline-flex;
}

.profile-dropdown {
	position: absolute;
	color: #ccc;
	top: 35px;
	right: 120px;
	border: black solid;
	background-color: black;
	padding: 5px;
	border-radius: 5px;
}

.logout-button-font {
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	font-size: 16px;
	background-color: black;
	border-color: #ccc;
	color: #fff;
	border-radius: 3px;
	padding: 2px 15px;
	margin-top: 5px;
}

.logout-button-font:hover {
	border-color: white;
	cursor: pointer;
}

.li {
	list-style: none;
}

.guest-log-in-button {
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	font-size: 16px;
	background-color: blue;
	border-color: #ccc;
	color: #fff;
	border-radius: 3px;
	padding: 2px 15px;
	float: right;
	margin-top: 15px;
	margin-right: 10px;
	/* padding: 0px */
	box-shadow: none;
}

.guest-log-in-button:hover {
	border-color: white;
	cursor: pointer;
}
