.album-container {
	margin: auto;
	max-width: 2480px;
}

.album-detail {
	display: flex;
	justify-content: center;
	width: 80%;
	margin: 0;
}
.album-detail-image {
	height: 150px;
	width: 150px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 30%;
}

.album-detail-info {
	display: inline-flex;
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	width: 100%;
	color: black;
	margin-top: 20px;
	margin-right: 5px;
	max-width: 550px;
	opacity: 1;
	height: 100%;
	width: 100%;
	opacity: 1;
	transition: opacity 0.3s;
	background: linear-gradient(
		135deg,
		rgb(232, 232, 232) 0%,
		rgb(121, 121, 121) 100%
	);
}

.list-song:hover {
	cursor: pointer;
	color: #f50;
}

#album-title {
	font-size: 30px;
	font-weight: bold;
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
}

#album-artist {
	font-size: 20px;
}

.album-detail-buttons {
	display: inline-flexbox;
	flex-direction: column;
	margin-top: 20px;
	min-width: 100px;
}
