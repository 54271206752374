/* .song-container {
	display: flexbox;
	margin: auto;
	align-items: center;
	max-width: 2480px;
	height: 1100px;
	border: green solid;
} */

.song-main-container {
	/* border: red solid; */
	display: flex;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	max-width: 1175px;
	height: 1100px;
	background-color: rgb(245, 245, 245);
}
.song-container {
	/* border: blue solid; */
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	justify-items: center;
	width: 1200px;
	height: 1020px;
}

.song-box {
	display: inline-flex;
	justify-content: center;
	margin: 20px;
	/* border: orange solid; */
	width: 275px;
	height: 275px;
}

.song-detail {
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	/* border: black solid; */
	width: 270px;
	height: 270px;
}
/* .song-detail:hover {
	cursor: pointer;
} */

.song-image {
	/* border: green solid; */
	width: 200px;
	height: 200px;
}
.song-title {
	/* border: black solid; */
	max-width: 250px;
	height: 20px;
}
.song-description {
	/* border: black solid; */
	word-break: break-all;
	/* width: 175px;
	height: 20px; */
}

/* .song-detail {
	min-width: 720px;
	display: flex;
	justify-content: left;
	max-width: 2480px;
	color: black;
} */

/* .song-detail-image {
	height: 150px;
	width: 150px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 30%;
} */

/* .song-list-item {
	display: grid;
	grid-template-areas:
	'x'
	'x'
	'x';
	border: orange solid;
	max-width: 200px;
	max-height: 200px;
	background: linear-gradient(
		135deg,
		rgb(232, 232, 232) 0%,
		rgb(121, 121, 121) 100%
	);
} */
/* .song-list-item {
	display: flexbox;
	justify-content: left;
	margin-top: 20px;
	width: 500px;
	font-size: 30px;
} */

.song-list-item:hover {
	cursor: pointer;
}

.ul-songs {
	display: grid;
	grid-template-areas:
		'X X X'
		'X X X'
		'X X X';
}

.ul-songs {
	display: flexbox;
	border: blue solid;
	/* display: inline-flex; */
	/* min-width: 720px; */
	max-width: 2480px;
	padding: 20px;
	gap: 20px;
}

.song-list-title {
	padding-left: 20px;
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
	font-size: 20px;
}

/* .song-list-image {
	height: 150px;
	width: 150px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 30%;
} */

/* .nav-pic {
	position: relative;
} */

li {
	list-style: none;
}
