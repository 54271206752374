body {
	margin: 0;
	padding: 0;
	font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
		Garuda, Verdana, Tahoma, sans-serif;
}

.home-container {
	margin: 0 auto;
	max-width: 1980px;
	background-color: rgb(238, 231, 231);
}

.home-container:hover {
	cursor: grab;
}
.home-container:active {
	cursor: grabbing;
}

.footer {
	display: flex;
	/* border: red solid; */
	margin: 0 auto;
	margin-top: 50px;
	height: 100%;
	width: 720px;
}

.icon {
	height: 75px;
	width: 75px;
}

.icon:hover {
	cursor: pointer;
}
